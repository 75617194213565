export default { // 오마이폼 관련 데이터
  optionTypes: ["checklist", "agreement", "checkbox", "select"],
  item: {
    types: [
      {name: "text", title: "텍스트"},
      {name: "textarea", title: "텍스트(Multi)"},
      {name: "email", title: "이메일"},
      {name: "tel", title: "연락처"},
      {name: "date", title: "날짜"},
      {name: "number", title: "숫자"},
      {name: "currency", title: "통화(0,000)"},
      {name: "file", title: "파일"},
      {name: "select", title: "단일 선택"},
      {name: "checkbox", title: "다중 선택"},
      {name: "personalId", title: "주민등록번호"},
      {name: "agreement", title: "동의서"},
      {name: "checklist", title: "점검표"},
    ]
  }
};