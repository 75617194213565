export default {
  colors: {
    "김정미": "#fcc006",
    "오현경": "#e88c31",
    "안성규": "#d3494e",
    "성진경": "#0f9b8e",
    "조일한": "#8a3cdd",
    "염기석": "#1f3b4d",
    "한송이": "#fe46a5",
    "안가연": "#007bff",
  },
  getStyle(name) {
    if (!this.colors[name]) {
      return {};
    }

    return {color: this.colors[name]};
  }
};